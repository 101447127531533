* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/*=======global btn csss======*/

body {
    font-family: 'Roboto', sans-serif;
}

p {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 2.2;
    text-align: justify;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3 !important;
    /*   color: #000;*/
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h5 {
    font-size: 16px;
}

ul {
    padding-left: 0rem;
}

.btn {
    padding: 5px 37px;
    background-color: #ff8038;
    /*    border-radius: 30px;*/
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    box-shadow: none !important;
}


.custom-calendar .react-datepicker__navigation--previous {
    left: 10px;
    top: 15px;
}

.custom-calendar .react-datepicker__navigation--next {
    right: 10px;
    top: 15px;
}


.bt-light {
    background-color: #243a69;
    padding-top: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 15px;
    letter-spacing: 1px;
    border: 1px solid #ff8038;
    transition: .25s;
    box-shadow: none;
    color: #fff;
}

.bt-light:hover {
    background-color: #ff8038;
    box-shadow: 0px 15px 20px rgba(255, 128, 56, 0.4);
    color: #fff;
    /* transform: translateY(-7px); */
    border: 1px solid #fff;
    box-shadow: none;
}

.fullc-or {
    background-color: #ff8038 !important;
}

.bt-drk {
    background-color: transparent !important;
    padding-top: 15px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 15px;
    letter-spacing: 1px;
    color: #ff8038;
    border: 1px solid #ff8038;
    transition: .25s;
    box-shadow: none;
}

.bt-drk:hover {
    background-color: #ff8038 !important;
    border: 1px solid #fff;
    box-shadow: none;
    box-shadow: 0px 15px 20px rgba(255, 128, 56, 0.4);
    color: #fff;
    transform: translateY(-7px);
}


/*=======global btn css======*/


/*.toggle-bar{
	   display: none;
}
*/


/*=======left-bar=======*/

.left-bar {
    border: 1px solid #ddd;
    position: fixed;
    width: 10%;
    /*	  height: 100%;*/
    left: 0;
    padding-top: 10px;
    border-top-right-radius: 10px;
    -webkit-appearance: none;
    box-shadow: 10px 10px 0px -9px rgba(0, 0, 0, 0.1);
}


/*.left-side {width:55%; height:800px; left:-10px;   position:sticky; top:0px;  box-shadow: 10px 10px 0px -9px rgba(0,0,0,0.1);
             border-top-right-radius: 10px;
	        -webkit-appearance: none;  border:1px  solid #ddd;}*/

.menu-sec i {
    font-size: 30px;
    color: #2f3b4d;
    line-height: 2;
    transition: .25s;
}

.menu-sec ul li {
    color: #000;
    text-align: center;
    list-style: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
}

.menu-sec a {
    color: #000;
    transition: .25s;
}

.menu-sec a:hover {
    color: #ff8038;
}

.menu-sec a:hover i {
    color: #ff8038;
}


/*=======left-bar=======*/


/*=======dashboard=======*/

.search input {
    height: 60px;
    text-indent: 32px;
    padding-top: 10px;
    border: 1px solid #000;
    color: #000;
    font-size: 14px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
}

.search input:focus {
    box-shadow: none;
    border: 1px solid #ff8038;
}

.search {
    position: relative;
}

.search .bi-search {
    position: absolute;
    top: 20px;
    left: 16px;
    color: #000;
}

.select-bx {
    position: relative;
}

.select-bx select {
    max-width: 120px;
    position: absolute;
    right: 10px;
    height: 60px;
    border: none;
    box-shadow: none !important;
    font-weight: 700;
}

.user-img {
    position: absolute;
    right: 139px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-image: url(..//image/user.jpg);
    background-size: cover;
}

.protfolio-sec {
    border: 1px solid #000;
    padding: 28px 35px 40px 35px;
    border-radius: 10px;
}

.left-protfolio select {
    height: 55px;
    box-shadow: none !important;
    font-weight: 700;
}

.Balance-bx {
    color: #fff;
    background-color: #243a69;
    padding: 4px 14px;
    border-radius: 10px;
}

.Balance-bx h4 {
    font-size: 2rem;
}

.two-selctone select {
    height: 36px;
    box-shadow: none !important;
    font-weight: 700;
}

.Portfolio-tablesec h6 {
    color: #ff8038;
}

.orc-table .table-dark th {
    background-color: #ff8038 !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
}

.mid-protfolio {
    position: relative;
}


/*.img-w-titel img{
              position: relative;
       }*/

.img-w-titelbx h1 {
    text-transform: capitalize;
    font-size: 1.2rem;
}

.img-w-titelbx1 h1 {
    font-size: 2rem;
    font-family: 'Nunito', sans-serif;
}

.img-w-titelbx2 h1 {
    text-transform: capitalize;
    font-size: 1.3rem;
}

.img-w-titelbx3 h1 {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Nunito', sans-serif;
}

.img-w-titelbx,
.img-w-titelbx1,
.img-w-titelbx2,
.img-w-titelbx3 {
    position: relative;
}

.img-w-titelbx h1,
.img-w-titelbx1 h1,
.img-w-titelbx2 h1,
.img-w-titelbx3 h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0 !important;
}

@media (min-width: 992px) and (max-width: 1199px) {

    .img-w-titelbx h1,
    .img-w-titelbx2 h1,
    .img-w-titelbx3 h1 {
        font-size: 1rem;
    }

    .img-w-titelbx1 h1 {
        font-size: 1.6rem;
    }

    .orc-table .table-dark th {
        font-size: 11px;
    }
}

@media (max-width: 767px) {

    .img-w-titelbx h1,
    .img-w-titelbx2 h1,
    .img-w-titelbx3 h1 {
        font-size: 14px;
    }

    .img-w-titelbx1 h1 {
        font-size: 22px;
    }

    .openttrade_transtion {
        overflow-x: scroll;
        width: 700px;
    }

    .openttrade_transtion .table-responsive {
        /* overflow-x: auto; */
        overflow: scroll;
        width: 830px;
    }
}

@media (max-width: 420px) {

    .img-w-titelbx h1,
    .img-w-titelbx2 h1,
    .img-w-titelbx3 h1 {
        font-size: 14px;
    }

    .img-w-titelbx1 h1 {
        font-size: 22px;
    }

    .openttrade_transtion {
        overflow-x: scroll;
        width: 600px;
    }

    .openttrade_transtion .table-responsive {
        /* overflow-x: auto; */
        overflow: scroll;
        width: 830px;
    }
}


/*=======dashboard=======*/


/*=======modal-content=======*/

.modal-content {
    padding: 30px;
}

.blackout-bx a {
    color: #000;
}

.rate-bx {
    padding: 14px 30px 14px 30px;
    background-color: #243a69;
    border-radius: 10px;
    color: #fff;
}

.rate-bx span {
    font-size: 1.5rem;
}

.form-bx input {
    height: 50px;
    border-radius: 5px;
    box-shadow: none !important;
}

.form-bx input:focus {
    border: 1px solid #ff8038;
    ;
}

.form-bx select {
    height: 50px;
    border-radius: 5px;
    box-shadow: none !important;
}

.form-bx select:focus {
    border: 1px solid #ff8038;
    ;
}

.value-bx1 span {
    background-color: #fff;
    color: #000;
    font-family: 'Poppins', sans-serif;
    border-radius: 5px;
}

.rate-bx1 {
    padding: 20px 10px 10px 10px;
    background-color: #243a69;
    border-radius: 10px;
    color: #fff;
}

.in-w-slec input {
    max-width: 600px;
}

.in-w-slec select {
    max-width: 200px;
}

.add-btn {
    box-shadow: none !important;
    color: #ff8038;
    font-weight: 600;
}

.cansel-btn {
    padding: 10px 80px 10px 80px;
    background-color: transparent;
    color: #ff8038;
    border: 1px solid #ff8038;
}

.save-btn {
    padding: 10px 80px 10px 80px;
    background-color: #ff8038;
    border: 1px solid #ff8038;
    color: #fff;
    transition: .25s;
}

.save-btn:hover {
    background-color: #243a69;
    color: #fff;
}

.menu {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.menu-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 7px 22px;
    border: 0;
    border-radius: 50px;
    line-height: 1.5em;
    color: black;
    text-decoration: none;
    font-size: 15px;
    background: white;
    list-style: none;
    box-shadow: 8px 8px 14px 0px rgb(50 50 50 / 11%);
}

.menu-active {
    background-color: #ff8038;
    color: #fff !important;
}

.blackout-bx.modal-header {
    border-bottom: none !important;
}

.btn-close {
    font-size: 19px;
    font-weight: 600;
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Montserrat', sans-serif;
    opacity: 1;
    background-image: none;
}

.value-bx1 {
    position: relative;
}


/*=======modal-content=======*/


/*=========foter==========*/

footer {
    padding-top: 40px;
    padding-bottom: 30px;
    background-color: #233a69;
    z-index: 9999999;
    position: relative;
    bottom: 0;
}

footer p {
    color: #fff;
}

.social i {
    padding: 10px 12.5px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 30px;
    color: #fff;
    transition: .25s;
    border-left-style: dotted;
    transition: .25s;
}

.social i:hover {
    background-color: #ff8038;
}


/*=========foter==========*/

@media only screen and (max-width:350px) {

    /*.toggle-bar{
	    display: block;
}
*/
    .left-bar {
        position: relative;
        width: 50%;
    }
}


/*calucaltor*/

.trade {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 32px 0px rgba(36, 40, 46, .12);
}

.trade h2 {
    color: #ff9152;
}

.frm-hd-t {
    padding: 8px 29px;
    background-color: #ff9152;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
}

.frm-hd-t1 {
    padding: 12px 29px;
}

.postion-frm {
    border: 1px solid #cbd0dd;
    padding: 20px 20px 10px 20px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.postion-frm label {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #525b75;
}

.postion-frm input {
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.postion-frm input:focus {
    box-shadow: none;
    border: 1px solid #ff9152;
}

.postion-frm select {
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.postion-frm select:focus {
    box-shadow: none;
    border: 1px solid #ff9152;
}

.radio-input input {
    height: 13px !important;
    margin-top: 6px;
}

.radio-input .form-check-input:checked {
    background-color: #ff8038 !important;
    border: 1px solid #ff8038 !important;
}

.radio-input label {
    padding-right: 10px;
    padding-left: 7px;
}

.commi-btn {
    padding: 8px 25px;
    background-color: #ff8038 !important;
    color: #525b75;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.commi-btn:hover {
    color: #fff;
    box-shadow: 0px 5px 20px rgba(255, 128, 56, 0.4);
}

.table-input {
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    line-height: 2;
    text-transform: capitalize;
    border: 1px solid #cbd0dd;
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.table-input input {
    height: 35px;
    border-radius: 3px;
    font-size: 13px;
    color: #525b75;
    transition: .25s;
}

.table-input input:focus {
    box-shadow: none;
    border: 1px solid #ff9152;
}

.table-input span {
    padding: 10px;
    font-size: 13px;
}

.table-input td {
    border: none;
}


/*///////////*/


/*summery*/

.tabs-sec {
    font-size: 13px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
}

.tabs-sec .nav-item .nav-link {
    border: 0;
    height: 60px;
    background-color: #f4f1f1;
    border-radius: 0px;
    color: #000;
    border-right: 1px solid #e9e6e6;
}

.tabs-sec .nav-pills .nav-link.active {
    background-color: #ff9152 !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffff;
}

.table-input1 {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 2;
    text-transform: capitalize;
    /*	   border:1px solid #cbd0dd;*/
    /* border-top: none; */
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}


/*////////*/

.padding-ds {
    padding: 10px 20px;
    background-color: #fed9d9;
    border-radius: 5px;
    color: #e37676;
}

.padding-btn-dtd img {
    max-width: 20px;
    padding-right: 5px;
}

.padding-btn-dtd {
    padding: 5px 17px;
    background-color: #54ffda;
    text-transform: capitalize;
    font-size: 11px;
}

.padding-btn-dtd:focus {
    box-shadow: none;
}

.edit-btn {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}

.delete-btn {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}

.open-btn {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}

.watch {
    background-color: #ffffff;
    color: #000;
    text-transform: capitalize;
    padding: 4px 10px;
}


/*admin css*/

.admin-table table {
    border-radius: 10px;
}

.admin-table th,
tr {
    padding: 15px 10px 15px 10px;
}

.data-import select {
    border: 1px solid #8392ab;
    box-shadow: none !important;
    border-radius: 5px;
}


/*admin css*/

.edit-btn-adm {
    border: 1px solid #dee2e6;
    background-color: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    border-radius: .375rem;
    font-size: 12px;
    padding: 6px 13px;
    color: #000;
    font-family: 'Montserrat', sans-serif;
}

.edit-btn-adm:hover {
    background-color: #eaeaea;
}

.delete-admin {
    border: 1px solid #dee2e6;
    background-color: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    border-radius: 0.375rem;
    font-size: 14px;
    /* padding: 7px 12px; */
    color: #f00;
}

.delete-admin:hover {
    color: #000;
}

.adm-btn {
    padding: 8px 20px;
    background-color: #bbc6ff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 2;
    box-shadow: none !important;
}

.import-bx {
    border: 1px dashed #8392ab;
    text-align: center;
    padding: 40px 20px 40px 20px;
    border-radius: 10px;
    box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .12) !important;
}

.import-bx span {
    padding: 10px 21px;
    background-color: #BDCDEF;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    border-radius: 30px;
}

.drop {
    padding: 12px 14px;
    background: #FF9E46;
    border-radius: 30px;
    color: #fff;
}

.import-bx input {
    margin-top: 20px;
    background: #fff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
}

.ad-tab-b {
    border-bottom: 1px solid #cbd0dd !important;
}


/*//////*/

.company-lg img {
    max-width: 60px;
    border-radius: 5px;
    border: 1px solid #e3e6ed;
    box-shadow: 0 4px 12px rgba(86, 141, 187, .12);
}

.cp-tail-bx {
    position: relative;
}

.bell-bx {
    position: absolute;
    top: 0;
    right: 40px;
}

.cp-tail-bx ul li {
    list-style: none;
    display: inline-block;
    padding: 9px 13px;
    border: 1px solid #e5faf5;
    border-radius: 30px;
    margin-right: 7px;
    border: 1.26923px solid 1px solid #00d09c;
    box-sizing: border-box;
    box-shadow: 0 4px 12px rgba(86, 141, 187, .12);
}

.cp-tail-bx a {
    color: #FF9E46;
}

.cp-tail-bx h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
}

.cp-tail-bx p {
    font-family: 'Roboto', sans-serif;
}

.cp-tail-bx h5 {
    font-size: 35px;
    font-family: 'Roboto', sans-serif;
}

.cp-tail-bx-asd h5 {
    font-size: 21px !important;
    padding-top: 19px;
    padding-left: 10px;
}

.cp-tail-bx span {
    font-size: 15px;
    color: #eb5b3c;
}

.id {
    color: #a0a0a0 !important;
}

.optino-btn-td {
    border: 1px solid #FF9E46;
    border-radius: 30px;
    padding: 5px 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #FF9E46;
    background-color: #ffffff;
    position: absolute;
    right: -27px;
    top: 94px;
}

.grp-img img {
    border-radius: 10px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    margin-bottom: 40px;
}

.bot-brd {
    border-top: 1px dashed #7c7e8c;
    width: 100%;
    height: 1px;
    background-color: transparent;
}

.detels-bx .nav-pills .nav-link.active {
    color: #fff;
    background: linear-gradient(51deg, rgba(36, 58, 105, 1) 3%, rgba(77, 102, 156, 1) 105%);
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
    border: 1px solid white;
}

.detels-bx .nav-item .nav-link {
    height: 40px;
    border-radius: 0px;
    background-color: transparent;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 800;
}

.new-bx {
    border: 1px dashed #afbcd2;
    padding: 10px 20px;
    height: 107px;
    border-radius: 10px;
    position: relative;
    box-shadow: rgb(143 187 245 / 35%) 7px 7px 0 0;
}

.new-bx span {
    padding: 6px 0px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
}

.new-bx h5 {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1.5 !important;
    color: #4b4747;
}

.slec-dta-ch li {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 3px 6px #000000b8;
    cursor: pointer;
    display: inline-block;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    margin-left: 2px;
    padding: 6px 17px;
    transition: .25s;
}

.slec-dta-ch li:hover {
    box-shadow: 0px 0px 0px #999;
}

.slec-dta-ch a {
    color: #000;
    /*    text-shadow: 1px 1px 5px #999;*/
}


/*Performance data code*/

.overview-sec {
    position: relative;
}

.slec-dta-ch {
    position: absolute;
    right: 30px;
    top: 373px;
    bottom: -21px;
}

.pr-mbx {
    border: 1px solid #FF9E46;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 0px 1px 9px rgba(255, 128, 56, 0.4);
    position: relative;
}

.pin-x {
    padding: 7px 12px;
    background-color: #ff7900;
    position: absolute;
    right: 12px;
    font-size: 18px;
    border-radius: 10px;
    color: #fff;
    top: 15px;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, .12);
}

.apt-bx {
    width: 120px;
    height: 111px;
    background-color: #BDCDEF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #ffffff;
}

.analyst-bx ul li {
    line-height: 2;
}

.progress {
    background-color: #e3e6ef;
    height: 10px;
    border-radius: 30px;
}

.progress-bar {
    background-color: #FF9E46;
}

.Fundamentals-bx {
    border: 1px solid #ddd;
    /* padding: 0px 10px 20px 10px; */
    border-radius: 10px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
}

.financials-bx {
    border: 1px dashed #ddd;
    border-radius: 10px;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
}

#more {
    display: none;
}

.shareholding-bx {
    position: relative;
}

.sh-ul {
    top: 0 !important;
    right: 0;
}

.news-ul {
    position: relative;
}

.new-ul {
    top: 0 !important;
    right: 0 !important;
}


/*Performance data code*/


/*calutero model*/

.cal-model {
    padding: 10px;
}

.postion-min-dta {
    border: 1px solid #cbd0dd;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.postion-min-dta label {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: #525b75;
}

.postion-min-dta input {
    font-size: 12px;
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    height: 35px;
    border-radius: 3px;
}

.calu-bx-modl select {
    height: 35px;
    border-radius: 3px;
    font-size: 12px;
    color: #525b75;
    font-family: 'Montserrat', sans-serif;
    transition: .25s;
}

.calu-bx-modl select:focus {
    border: 1px solid #ff9152;
    box-shadow: none;
}

.commi-btn-link {
    background-color: transparent;
    text-transform: capitalize;
    padding: 0px;
}

.commi-btn-link {
    color: #ff8038 !important;
    text-decoration: underline;
}

.active-buy {
    color: #55bd6c !important;
    border-bottom: 3px solid #55bd6c !important;
}

.select-limit {
    height: 39px !important;
    border: 2px solid #ced5d9;
    padding: 0px 8px;
}

.select-limit:focus {
    outline: none !important;
    border: 2px solid #ced5d9 !important;
}

.min-overview select {
    font-family: 'Montserrat', sans-serif;
    border: none;
    width: 70% !important;
}

.min-overview input {
    width: 70% !important;
}

.min-overview label {
    width: 30% !important;
}

.input_border:focus {
    box-shadow: none;
    border-color: #c1c1c1;
}

.abouut-select-data label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.abouut-select-data select {
    height: 45px;
    border: 1px solid #e2e8f0;
    box-shadow: 0 0 24px 5px hsla(0, 0%, 50%, .07);
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.abouut-select-data select:focus {
    border: 1px solid #ff9152;
    box-shadow: none !important;
}

.abouut-select-data th {
    padding: 15px 0px;
}

.abouut-select-data td {
    padding: 15px 0px;
}

.column_gap-12 {
    column-gap: 12px;
}

.a .l-navbar {
    width: var(--nav-width);
    /* height: 100vh; */
    background-color: #eff3f900;
    padding: .5rem 0 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
    color: white;
}



.a .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}

.label_grey_input {
    color: #525b75 !important;
}

.border-2px {
    border-left: 2px solid #ced5d9;
    margin: 6px 10px;
}

.provider-min-md {
    padding: 2px;
}

.provider-datafd {
    font-size: 10.5px;
}

button.btn-close {
    position: absolute;
    top: 42px;
    right: 6px;
    padding: 3px 7px 9px 7px;
    background: #ff7900;
    border: 1px solid #fff;
    color: #fff;
}


/*calutero model*/

.new-bx p {
    color: #000;
    font-size: 14px;
}

span.doller-d {
    position: absolute;
    left: 6px;
    background: transparent;
    top: 57px;
    /* padding-right: 20px; */
}

span.min-d-bx {
    position: relative;
}

.value-bx1 input {
    padding-left: 20px;
    height: 35px;
}

.d-flex.position-relative img {
    height: 80px;
    width: 120px;
}


/* responsive sec */

@media only screen and (min-width:768px) and (max-width:1024px) {
    .right-protfolio {
        margin-top: 5%;
    }

    .modal-dialog {
        max-width: 690px;
        margin: 0.5rem auto;
    }

    .orc-table .table-dark th {
        font-size: 11px;
    }
}

@media only screen and (min-width:1024px) and (max-width:1100px) {
    .right-protfolio {
        margin-top: 5%;
    }

    .modal-dialog {
        max-width: 690px;
        margin: 0.5rem auto;
    }
}


/* comment css*/

.comments-input input {
    height: 80px;
}


/*trans*/

.postion-frm textarea {
    height: 80px;
    box-shadow: none !important;
    transition: .25s;
}

.postion-frm textarea:focus {
    border: 1px solid #ff9152;
    box-shadow: 0px 1px 9px rgba(255, 128, 56, 0.4) !important;
}

.ancer-k {
    color: blue !important;
    text-transform: capitalize !important;
    text-decoration: underline;
}

.com_na {
    background-color: transparent;
    color: #0056ff;
    padding: 0;
    text-decoration: underline;
}


/* start 05-06-2023 */

.a .dropdown {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.a .dropdown-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.a .dropdown-body {
    padding: 5px;
    border: 1px solid #dbc3c3;
    display: none;
    border-radius: 5px;
}

.a .dropdown-body.open {
    display: block;
    position: absolute;
    background-color: white;
    width: inherit;
    margin-top: 1px;
    z-index: 1111;
}

.a .dropdown-item {
    padding: 10px;
    overflow-x: hidden;
}

.a .dropdown-item:hover {
    cursor: pointer;
}

.a .dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

.a .dropdown-item-dot.selected {
    opacity: 1;
}

.a .icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.a .icon.open {
    transform: rotate(90deg);
}

.a .dropdown-item:active {
    color: #1e2125;
    background-color: #e9ecef;
}

.input-err-color {
    border: 2px solid rgb(197 0 0) !important;
}


/*end  start 05-06-2023 */

.order_history .main-head {
    background: #0D1440;
    box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
    padding: 1rem;
    margin-bottom: 0;
    margin-top: 0rem;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 16px;
}

.order_history .pricing-table {
    background: #fff;
    box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);
    padding: 0px 20px;
    border-radius: 4px;
    transition: .3s;
}

.order_history .pricing-table:hover {
    box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, .15);
}

.order_history .pricing-table .pricing-label {
    border-radius: 2px;
    padding: .25rem .5rem;
    margin-bottom: 1rem;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
}

.order_history .pricing-table h2 {
    color: #3b3b3b;
    font-size: 24px;
    font-weight: 500;
}

.order_history .pricing-table h5 {
    color: #B3B3B3;
    font-size: 14px;
    font-weight: 400;
}

.order_history .pricing-table .pricing-features {
    margin-top: 0rem;
}

.order_history .pricing-table .pricing-features .feature {
    font-size: 14px;
    margin: .5rem 0;
    color: #B3B3B3;
}

.order_history .pricing-table .pricing-features .feature p {
    /* display: inline-block;
    float: right; */
    color: #6c757d;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0px;
}

.order_history .pricing-table .price-tag {
    margin-top: 2rem;
    text-align: center;
    font-weight: 500;
}

.order_history .pricing-table .price-tag .symbol {
    font-size: 24px;
}

.order_history .pricing-table .price-tag .amount {
    letter-spacing: -2px;
    font-size: 64px;
}

.order_history .pricing-table .price-tag .after {
    color: #3b3b3b;
    font-weight: 500;
}

.order_history .pricing-table .price-button {
    display: block;
    color: #fff;
    margin-top: 2rem;
    padding: .75rem;
    border-radius: 2px;
    text-align: center;
    font-weight: 500;
    transition: .3s;
}

.order_history .pricing-table .price-button:hover {
    text-decoration: none;
}

.order_history .pricing-table .pricing-features .feature {
    margin: 0;
    color: #314772;
    border-bottom: 0px solid #eee;
    padding: 0.0rem 0;
    font-weight: 600;
    text-transform: uppercase;
}

.scroll_order_history {
    overflow: auto;
    /* height: 500px; */
}

.modal_order_history {
    padding: 20px 0px;
}

.scroll_order_history::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
    background: #eee;
}

.scroll_order_history::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
    background: #eee;
}

.scroll_order_history::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #fd7e14;
}

.scroll_order_history::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #fd7e14;
}

.modal_order_history .btn-close-provider {
    position: absolute !important;
    top: 37px !important;
    right: 43px !important;
    padding: 3px 7px 10px 7px !important;
}

.price_center p {
    text-align: center;
}

.green1 {
    color: green !important;
    transform: translate(0px, 14px);
}

.red1 {
    color: red !important;
    transform: translate(0px, 14px);
}

.price_right p {
    text-align: end;
}

@media (min-width: 280px) and (max-width: 767px) {
    .order_history {
        padding: 0;
    }

    .modal_order_history {
        padding: 10px;
    }

    .order_history .pricing-table {
        padding: 0px 20px;
    }

    .modal_order_history .btn-close-provider {
        position: absolute !important;
        top: 28px !important;
        right: 30px !important;
        padding: 3px 7px 10px 7px !important;
    }

    .price_right p {
        text-align: end;
    }
}

.spinner {
    border: 2px solid #ccc;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

h4.herofont {
    font-size: 27px;
}

.summary-header-h {
    background: color(srgb 0.77 0.3 0.04);
    border: 0 !important;
}

.summary-header-h * {
    color: #fff !important;
}

.summary-header-h h6 {
    font-size: 14px !important;
    margin-bottom: 5px !important;
}

.summary-header-h h5 {
    font-size: 16px;
    font-weight: 400 !important;
    color: #e7e7e7 !important;
    margin-bottom: 0;
}

.summary-header-h>div {
    border-left: 4px solid color(srgb 1 1 1 / 0.35);
    padding-left: 10px;
    border-radius: 8px;
}

.summary-body-h {
    background: #183a58;
    border: 0 !important;
}

.summary-body-h h6,
.summary-body-h h5:not([style="color: green;"]):not([style="color: red;"]) {
    color: #fff !important;
}

.summary-body-h h5[style="color: red;"],
.summary-body-h h5[style="color: red;"] {
    color: #ff685c !important;
}

.summary-body-h h5[style="color: red;"]+span svg {
    position: relative;
    top: -6px;
    left: 2px;
}

.summary-body-h h6 {
    margin-bottom: 4px !important;
    font-size: 14px !important;
}

.summary-body-h h5[style="color: green;"] {
    color: #01c568 !important;
}

.summary-body-h h5[style="color: green;"]+span {
    color: #00df75 !important;
    position: relative;
    top: -6px;
    left: 2px;
}

.summary-body-h h5 {
    font-size: 16px !important;
}

.open-close-stocks-tab-h>.tabs-sec:first-child li.nav-item button.nav-link {
    border-bottom: 1px solid #788791;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    background: linear-gradient(90deg, #e2e7ec 0%, #ebf0f5 23%);
    border-right-color: #788791;
}

.open-close-stocks-tab-h>.tabs-sec:first-child li.nav-item button.nav-link.active {
    background: transparent !important;
    border-bottom-color: transparent;
    border-top-color: #788791;
    border-right-color: #788791;
    border-left-color: transparent;
    color: #000;
}

.open-close-stocks-tab-h>.tabs-sec:first-child ul>li:last-child>.nav-link:not(.active) {
    border-right: 1px solid transparent;
}

.open-close-stocks-tab-h>.tabs-sec+.tabs-sec button.nav-link.active {
    background: #ff8038 !important;
}

.open-close-stocks-tab-h>.tabs-sec+.tabs-sec {
    max-width: 500px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}

.open-close-stocks-tab-h>.tabs-sec+.tabs-sec button {
    height: auto;
    padding: 5px;
}

.open-close-stocks-tab-h {
    padding-top: 30px;
}

.open-close-stocks-tab-h>.tabs-sec+.tabs-sec button.nav-link {
    border-radius: 100px;
    background: #ebf0f5;
}

.open-close-stocks-tab-h>.tabs-sec+.tabs-sec .nav-item {
    margin-right: 15px;
}

.open-close-stocks-tab-h>.tabs-sec {
    max-width: 645px;
    margin: 0 auto;
}

.open-close-stocks-tab-h>.tabs-sec+.tabs-sec+.tab-content .about-content-bx {
    background: #eee !important;
    /* max-width: 645px;
    margin: 30px auto; */
}

.summary-tabs-graph-table-h {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.summary-tabs-graph-table-h .tbh-tabs-graph {
    width: 645px;
    margin-right: 30px;
}

.summary-tabs-graph-table-h .tbh-below-tabs-table {
    width: calc(100% - 675px);
    margin-top: 40px;
}

.summary-tabs-graph-table-h .tbh-below-tabs-table>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.summary-tabs-graph-table-h .tbh-below-tabs-table>div>div {
    width: 100% !important;
}

.open-close-stocks-tab-h>.tabs-sec+.tabs-sec+.tab-content>div>div {
    background: #eee;
    padding: 15px 20px;
    margin: 30px auto;
}

.h-78per {
    height: 78%;
}

