.anychartview .search-input {
  height: 32px;
  width: 170px;
  border-radius: 40px;
  padding: 10px;
  border: 1px solid #2c2929;
}
.anychartview .dropdown-color-fill-icon {
  position: relative;
  float: left;
  margin-left: 0;
  margin-right: 0;
}
.anychartview .color-fill-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  background-color: #fff;
  margin: 2px;
}
.anychartview .column_anyChrart button {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #000;
  padding: 12px;
  width: 45px;
  height: 45px;
}
.anychartview .column_anyChrart_secondchart button {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #000;
  padding: 12px;
  width: 45px;
  height: 45px;
}
.anychartview select {
  border-radius: 5px;
  height: 40px;
  padding: 4px 10px;
  font-size: 14px;
}

.sidenav {
  background-color: #fff;
  min-height: 100%;
}
.sidenav li {
  margin-top: 10px;
}

.top-nav {
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 4px solid #e9ecef;
}

.btn_publish {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  border-radius: 5px;
}

.btn_allbutton .btn {
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 30px;
  color: #131722;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  box-shadow: none !important;
  line-height: 0.9;
}

.btn_lr_load::before {
  border-left: 1px solid;
  content: "";
  font-size: 26px;
  border-bottom: solid 1px #cbcbcb;
  position: relative;
  left: 130px;
  top: 0px;
  z-index: 1;
  transform: rotate(90deg);
  color: #cfcfcf;
}

.btn_lr_load::after {
  border-left: 1px solid;
  content: "";
  font-size: 26px;
  border-bottom: solid 1px #cbcbcb;
  position: relative;
  left: -125px;
  top: 0px;
  z-index: 1;
  transform: rotate(90deg);
  color: #cfcfcf;
}

.flex-lg-fill {
  padding-left: 4px;
  background: #e9ecef;
  padding-right: 3px;
}

.title_sec {
  background: #fff;
  padding: 0px 0px;
}

.topnav_anychart .btn_publish {
  background-color: #ff8038;
  border-radius: 5px;
  color: #fff;
  padding: 5px 15px;
  margin-left: 40px;
}

.topnav_anychart .btn_publish:hover {
  background-color: #243a69;
}

.form-control:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25);
}

.anychartview select {
  border-color: #ddd;
}

.title_sec h3 {
  margin-bottom: 4px;
  border-radius: 5px;
  padding: 10px 0px;
}

.btn_allbutton button svg {
  font-size: 24px;
}

.topnav_anychart button svg {
  font-size: 24px;
}

.wishlist_head .btn {
  padding: 5px 15px;
  font-size: 14px;
}

.whishlis {
  padding: 10px;
}

.icon_50_name {
  font-size: 14px;
  text-align: center;
}

.rank_1 {
  font-size: 14px;
  text-align: center;
}

.rank_2 {
  font-size: 14px;
  text-align: center;
}

.rank_3 {
  font-size: 14px;
  text-align: center;
}

span.icon_50 img {
  border-radius: 6px;
}

span.icon_50 {
  margin-right: 5px;
}

.row.whishlis:hover {
  background: #e9ecef;
}

.whishlis {
  padding: 2px 10px;
}

.whishmmainhead {
  border-bottom: 1px solid #ddd;
}

.list_tbl_sec {
  overflow-x: hidden;
  height: 200px;
  border-bottom: 1px solid #ddd;
}

.list_tbl_sec::-webkit-scrollbar {
  background: #aaaaaa;
  width: 4px;
}/*# sourceMappingURL=anychart.css.map */