.btn_anyChart{
    color: black;
    background-color: white;
    border: 1px solid #ddd;
    margin: 0px 10px;
    padding: 5px 5px;
}
.color-fill-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    background-color: #fff;
    margin: 2px;
}

.colorpickerplus-custom-colors,
.colorpickerplus .input-group {
    display: none;
}
.input_counting input {
    width: 47px;
    background: #fff0;
    border: #eee 1px solid;
    padding: 3px;
    border-radius: 5px;
}
.dropdown-color-fill-icon {
    position: relative;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-top: 2px;
}
.edit_main_child button svg {
    font-size: 24px;
}
.title-drawing-tools {
    display: none;
}

.text-drawing-tools {
    display: none;
    max-width: 1080px;
    margin-bottom: 20px;
}

#chart-container {
    height: 550px;
}

#annotation-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    /* width: 100px; */
    position: absolute;
    z-index: 99999999999;
    top: 150px;
}

.table-container {
    padding: 0;
    width: 100%;
    height: 100%;
}

.table-container td {
    vertical-align: top;
}

.chart-column {
    height: 100%;
}

.no-iframe #chart-container {
    width: 99%;
}

.container-drawing-tools #chart-container {
    margin-top: 0;
}

.drawing-tools-solo.container {
    margin-top: 25px;
}

.container-drawing-tools .drawing-tools-solo {
    margin: 0;
}

* {
    outline: none !important;
}

.select > .btn {
    font-weight: 400;
    border-color: #c5c8d1;
    background: #fff;
}

.bootstrap-select > .btn {
    width: 100%;
    padding-right: 25px;
}

.error .bootstrap-select .btn {
    border: 1px solid #b94a48;
}

.control-group.error .bootstrap-select .dropdown-toggle {
    border-color: #b94a48;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
}

.bootstrap-select .dropdown-toggle {
    height: 34px;
    padding: 8px 16px;
    font-weight: 400;
    border-color: #c5c8d1;
    background: #fff;
}

.filter-option {
    font-size: 14px;
    float: left;
}

.btn-group-container {
    display: inline-block;
}

.toolbar > .select-container {
    display: inline-block;
}

.select .ac {
    margin-right: 5px;
    top: -1px;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    outline-offset: 0 !important;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
    margin-left: -1px;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*=col-] {
    margin-left: -1px;
}

.btn-group .select.select-marker-size {
    margin-left: -5px !important;
}

.btn-group .select {
    margin-left: -1px !important;
}

.select-marker-size[disabled="disabled"] > .btn:first-child {
    opacity: 0.75;
    cursor: not-allowed;
}

.select-marker-size[disabled="disabled"] .btn.active,
.select-marker-size[disabled="disabled"]  .btn:active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.choose-drawing-tools.bootstrap-select.btn-group ul.dropdown-menu {
    margin-right: 10px;
}

.choose-marker.bootstrap-select.btn-group ul.dropdown-menu {
    margin-right: 10px;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    text-overflow: ellipsis;
}

.btn-group .btn {
    margin-bottom: 5px;
}

.btn-group .volume-btn {
    display: inline-block;
    float: none;
}

.btn-group {
    font-size: 0;
}

.ac.glyphicon {
    font-family: 'Glyphicons Halflings' !important;
}

button.dropdown-toggle {
    padding-left: 13px !important;
    padding-right: 25px !important;
}

.filter-option .ac,
.dropdown-menu .ac {
    font-size: 14px;
}

/*Loader------------------------------------------------------------------------------------------------------------------------------------*/
.anychart-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #fff;
}

.anychart-loader .rotating-cover {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    left: 50%;
    margin-left: -35px;
}

.anychart-loader .rotating-plane {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20%;
    border: 5px solid #1c75ba;
    margin: 0 auto;
    position: relative;
    -webkit-animation: rotateplane 3s infinite;
    animation: rotateplane 3s infinite;
}

.anychart-loader .chart-row {
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 10px;
    letter-spacing: -3px;
    line-height: 0;
    font-size: 0;
    white-space: nowrap;
}

.anychart-loader .chart-row .chart-col {
    display: inline-block;
    width: 25%;
    height: 90%;
    background: #000;
    margin: 0 12.5% 0 0;
    vertical-align: bottom;
}

.anychart-loader .chart-row .chart-col.green {
    background: #26a957;
    height: 50%;
    -webkit-animation: blinkplane 1.5s infinite;
    animation: blinkplane 1.5s infinite;
}

.anychart-loader .chart-row .chart-col.orange {
    background: #ff8207;
    height: 70%;
    -webkit-animation: blinkplane 1.5s infinite 0.15s;
    animation: blinkplane 1.5s infinite 0.25s;

}

.anychart-loader .chart-row .chart-col.red {
    background: #f0402e;
    height: 90%;
    -webkit-animation: blinkplane 1.5s infinite 0.3s;
    animation: blinkplane 1.5s infinite 0.5s;
}

@keyframes rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        opacity: 1;
    }
    25% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        opacity: 0.3;
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        opacity: 1;
    }
    75% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(-180.1deg);
        transform: perspective(120px) rotateX(0deg) rotateY(-180.1deg);
        opacity: 0.3;
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        opacity: 1;
    }
}

@keyframes blinkplane {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.01;
    }
    100% {
        opacity: 1;
    }
}

#annotation-labela{
    position: absolute;
    z-index: 99999999999;
    top: 150px;
}
.multiSelect_width{
    width: 120px;
    position: relative;
}
.multiSelect_width input{
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding: 0px 10px;
    text-transform: none !important;
}
.multipalSelect_option{
    position: absolute;
    z-index: 99999;
    border: 1px solid rgb(193 193 193);
    background-color: white;
    padding: 16px;
    height: 250px;
    width: 365px;
    overflow-y: auto;
    left: 46px;
    top: -94px;
    border-radius: 6px;
}
.button_border_size{
    padding: 0px !important;
    line-height: 5px !important;
    width: 86px !important;
    height: 27px !important;
    margin: 3px 3px;
    border: 1px solid #c1c1c1;
    border-radius: 3px;
    background-color: white;
}
.multipalSelect_option p{
        line-height: 1.5;
        margin-bottom: 0px !important;
}
.selected_strock{
    background-color: #c5c5c5;
}
.progressBar_font{
    width: 150px;
    position: absolute;
    left: 46px;
    z-index: 11111111;
    padding: 10px;
    top: -4px;
    background-color: white;
    border: 1px solid #a69c9c;
}
.progress-container {
    background-color: #c9d2d8;
    height: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .progress-bar {
    width: 0;
    height: 100%;
    background-color: #4CAF50;
  }
  .progressBar_font p{
    text-align: center;
    line-height:1.5;
    margin-bottom: 0px !important;
  }
  .clickable_drager{
    height: 10%;
  }
  .dropdown_symbol_icon{
    position: absolute;
    font-size: 20px;
    z-index: 999999;
    /* left: 10px; */
    right: 10px;
    top: 2px;
  }
  .column_anyChrart{
    height: 32px;
  }
  .anyChartSelect{
    width: 170px !important;
    margin-left: 10px;
  }
  .stockListScrollar{
    height: 400px;
    overflow: auto;
  }
  .topnav_anychart{
    margin-left: auto;
  }
  /* .topnav_anychart button{
    background-color: #fff;
    border: 1px solid #ddd;
    color: #000;
    padding: 0px !important;
    width: 44px !important;
    height: 32px !important;
  } */
  .topnav_anychart button {
    background-color: #fff0;
    border: 1px solid #ddd0;
    color: #000;
    /* padding: 0px !important; */
    /* width: 44px !important; */
    /* height: 15px !important; */
    border-radius: 0;
}
.charting_level_btn_main {
    padding: 10px;
    line-height: 5px;
    width: 40px;
    height: 40px;
}
.makerSelect_option button {
    padding: 8px;
    line-height: 5px;
    width: 40px;
    height: 37px;
    margin: 3px 4px;
    border: 1px solid #c1c1c1;
    background-color: white;

}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    /* background-color: #ff7900 !important; */
}
.select_stock_provider{
    width: 190px;
}
.select_stock_input_field{
    border: none;
    background-color: white;
}
.select_stock_input_field.search-input span svg {
    margin-bottom: 2px;
}
.indicator_div_left{
        
        border-right: 1px solid #c1c1c1;
    
}
.indicator_div p {
    line-height: 2;
    padding-top: 0px;
    padding-left: 8px;
    margin-bottom: 0px;
    margin-left: 0px;
    cursor: pointer;
    color: #fff;
}
.indicator_div p:hover{
    background-color: #0000000f;
}
.main_div_ind{
    border-top: 1px solid #c1c1c1;
    margin-top: 18px;
    padding-top: 18px;
}
.favorite_icon_main {
    display: inline-block;
    margin-right: 3px;
    min-width: 16px;
}
.favorite_icon{
    display: none;
}
.indicator_div p:hover .favorite_icon{
    display: inline-block;
}
.selectedIndicators_main{
    position: absolute;
    z-index: 11;
    top: 79px;
    left: 66px;
}
.selectedIndicators_main p{
    line-height: 1.5;
    margin-bottom: 8px;
    background-color: white;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    padding: 2px 10px;
    width: auto;
}
.show_icons_indicator{
    display: none;
    cursor: pointer;
}
.selectedIndicators_main .list_indicator:hover .show_icons_indicator{
    display: inline-block;
}

.list_indicator {
    font-size: 14px;
    border: 1px solid #ddd;
    margin-bottom: 4px;
    border-radius: 5px;
    padding: 0px 10px;
    background: #fbfbfb;
    width: 250px;
    transition: 4s;
}
.list_indicator:hover {
    background: #fbfbfb;
    width: auto;
}
/* newcss06--11-23 */
.togle_button .card.card-body{
    width: 400px;
}
.togle_button {
    background: #fff;
    padding: 10px;
    border-left: 1px solid #e9ecef;
}
.togle_button button {
    padding: 5px 10px;
    background-color: #ffffff00;
    /* border-radius: 30px; */
    color: #000;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    box-shadow: none !important;
    border-color: #ddd;
}
.form_serach .form-control {
    font-size: 14px;
    color: #212529;
    border: 1px solid #ddd;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    height: 28px;
}
.form_serach .btn {
    padding: 1px 7px;
    background-color: #ffffff;
    /* border-radius: 30px; */
    color: #000;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    box-shadow: none !important;
    border-radius: 0px 5px 5px 0px;
    border-left: 0;
    border-color: #ddd;
}
.togle_button button:hover {
    background-color: #f3f3f3;
    /* border-radius: 30px; */
    color: #000;
    border-color: #000000;
}
.row.wishlist_head {
    padding: 10px;
}
.icon_view  {
    line-height: 1.8;
    text-align: center;
}
.icon_view i {
    font-size: 24px;
    text-align: center;
}
.wishlist_open{
    width: 350px;
}
.row.whishmmainhead .menu_sbl {
    background-color: #e9ecef;
    text-align: center;
    font-size: 13px;
    padding: 1px 5px;
}
/* newcss08-11-23 */
.tools_sec .btn-group, .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group>.btn:first-child {
    margin-left: 0;
}
.tools_sec .btn-group .btn {
    margin-bottom: 0px;
}
.tools_sec .btn-group-vertical > .btn, .btn-group > .btn {
    margin-left: -1px;
}
.tools_sec .btn-group-vertical>.btn, .btn-group>.btn {
    position: relative;
    float: left;
}
[class*=" ac-"], [class^=ac-] {
    font-family: anychart!important;
    /* speak: none; */
    font-style: normal;
    font-weight: 400!important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.tools_sec .toolbar .btn {
    padding: 7px 11px;
    background-color: #ffffff;
    /* border-radius: 30px; */
    color: #000000;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    box-shadow: none !important;
    border: 1px solid #ddd;
    margin-top: 2px;
}
.tools_sec .toolbar .hidden-xs {
    margin-left: 2px;
    margin-right: 2px;
}
.modal_indigater_sec .btn-close {
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e') center/1em auto no-repeat;
    border-radius: 0.25rem;
    opacity: 0.5;
    top: 23px;
    right: 20px;
}
.modal_indigater_sec .modal-lg, .modal-xl {
    /* max-width: 460px; */
}
.modal_indigater_sec .form-select {
    font-size: 14px;
    padding: 0.375rem 1.45rem 0.375rem 0.75rem;
}
.modal_indigater_sec .col-form-label {
    font-size: 14px;
}
.modal_indigater_sec .form-check-label {
    font-size: 14px;
}
.modal_indigater_sec .small.text-muted.form-text{
    font-size: 12px;
}
.modal_indigater_sec .form-control{
    font-size: 14px;
}
.modal_indigater_sec .form-label .btn{
    font-size: 14px;
}
.modal_indigater_sec .footer_md .form-select {
    padding: 0.375rem 1.45rem 0.375rem 0.75rem !important;
}
.footer_md .btn {
    margin-left: 5px;
    padding: 6px 15px;
    border: 0px;
}
.modal_indigater_sec .footer_md .col-form-label {
    font-size: 14px;
    text-align: end;
}
.btn_okcencle{
        padding-top: calc(0rem + 0px);
}
.footer_md {
    /* margin: 0px 15px; */
    border-top: 1px solid #eee;
    padding: 20px 15px;
}
.modal_indigater_sec .modal-content {
    padding: 0px;
    top: 10px;
    left: 0;
    right: 0;
}

/*new code */
  /*********************************
    APTO Dropdown Styles
  **********************************/

 .icondrop_selct .apto-dropdown-wrapper {

    width: 85px;
    height: 45px;
    float: left;
    position: relative;
    border:1px solid #e5e5e5;
  }
  
  .icondrop_selct .apto-trigger-dropdown {
  
    width: 85px;
    height: 45px;
    background-color: #fff;
    border: 0;
    padding: 0 20px;
    transition: 0.2s ease-in;
    cursor:pointer;
  }
  
  .icondrop_selct .apto-trigger-dropdown:hover {
    background-color:#eee;
  }
  
  .icondrop_selct .apto-trigger-dropdown .fa-caret-down {
  
    float: right;
    line-height: 22px;
  }
  
  .icondrop_selct .apto-trigger-dropdown svg {
  
    width: 25px;
    float: left;
    height: 25px;
  
  }
  
  .icondrop_selct .dropdown-menu {
    width: 180px;
    display:none;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 45px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  }
  
  .icondrop_selct .dropdown-menu.show {
    display:block;
  }
  
  .icondrop_selct .dropdown-item svg {
    width: 25px;
    height: 25px;
    float: left;
    margin-right:10px;
    color:#222;
  }
  
  .icondrop_selct .dropdown-item {
    width: 100%;
    height: 50px;
    line-height: 25px;
    border: 0;
    padding: 0 20px;
    cursor: pointer;
    transition:0.2s ease-in;
    background-color:#fff;
    font-weight: 700;
    font-family: Montserrat, serif;
    color: #5a616c;
    text-align: left;
  
  }
  
  .icondrop_selct .dropdown-item:hover {
    background-color:#e5e5e5;
  }
  
  .icondrop_selct .dropdown-item:not(:last-child){
    border-bottom: 1px solid #e5e5e5;
  }
  .charting_option_main {
    box-shadow: 3px 7px 11px #0000001a;
    position: absolute;
    z-index: 999;
    left: 70px;
    top: 0px;
    width: 300px;
    background-color: #fff;
    padding: 10px 0px;
    border-radius: 4px;
    border: 1px solid #827f7f;
}
.charting_option_main_child {
    box-shadow: 3px 7px 11px #0000001a;
    position: absolute;
    z-index: 999;
    left: 302px;
    top: 0px;
    width: 250px;
    background-color: #fff;
    padding: 10px 0px;
    overflow: auto;
    height: 400px;
    border-radius: 4px;
    border: 1px solid #827f7f;
}
.charting_option_main_child::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
}
.charting_option_main_child::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: rgba(114,127,146,.3);
}
  .charting_show{
    display: block ;
  }
  .charting_hide{
    display: none;
  }
  .charting_option_main ul li {
    list-style: none;
  }
  .charting_ul li {
    margin-top: 0px;
  }
  .list_charting {
    display: flex;
    padding: 5px 10px;
}
.list_charting .count_secd {
    width: 5%;
}

.list_charting .count_secb {
    width: 75%;
}
.list_charting .count_seca {
    width: 20%;
}
.charting_option_main_child li{
    padding: 5px 10px;
}
.count_seca span {
    background: #243a69;
    padding: 4px 8px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
}
.list_charting:hover .count_seca span {
    background: #ff8038;
}
.list_charting:hover {
    background: rgb(233, 236, 239);
}
.charting_option_main_child li{
    display: flex;
}
.charting_option_main_child li .chiled_seca {
    width: 15%;
}

.charting_option_main_child li .chiled_secb {
    width: 70%;
}
.charting_option_main_child li .chiled_secd {
    width: 15%;
    text-align: center;
}
.charting_option_main_child li .chiled_secd span svg {
    color: #fff;
}
.charting_option_main_child li:hover .chiled_secd span svg {
    color: #999;
}
.charting_option_main_child li:hover {
    background: rgb(233, 236, 239);
}
.edit_main_move svg {
    width: 40px;
    font-size: 24px;
}
.tools_edit {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    left: 37%;
    z-index: 11111;
    position: absolute;
    top: 10px;
    left: 37%;
    background-color: #ffffff;
    box-shadow: -1px 6px 20px 0px rgb(0 0 0 / 16%);
    /* height: 100%; */
    border-radius: 10px;
}
.select_option_dropDown select {
    word-wrap: normal;
    padding: 2px 6px;
    background: #f0f8ff00;
    border: 0;
}
.edit_main_child button {
    padding: 5px 10px;
    margin: 1px 2px;
    border-radius: 4px;
}
.edit_main_child {
    /* width: 350px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.edit_main_child button:hover {
    background: #e9ecef;
}
.iocn_edit_option {
    padding: 5px 10px;
    background: #ffffff;
    border: 1px solid #ffffff;
}
.tools_edit {
    padding: 2px 12px;
}
.edit_main .ms-auto.button-padding {
    padding: 0px 6px 10px 6px !important;
}
.topnav_anychart {
    display: block;
}

/* newdropwdown */

.drop_down_sec .apto-dropdown-wrapper {

    width: 130px;
    height: 30px;
    /* float: left; */
    position: relative;
    /* border:1px solid #e5e5e5; */
  }
  
  .drop_down_sec .apto-trigger-dropdown {
    width: 130px;
    height: 30px;
    background-color: #fff;
    border: 0;
    padding: 0 0px;
    transition: 0.2s ease-in;
    cursor: pointer;
    justify-content: space-around;
    line-height: 1.7;
    padding: 1px 0;
}
  .drop_down_sec .apto-trigger-dropdown:hover {
    background-color:#fff;
  }
  
  .drop_down_sec .apto-trigger-dropdown .fa-caret-down {
  
    float: right;
    line-height: 22px;
  }
  
  .drop_down_sec .apto-trigger-dropdown svg {
  
    width: 25px;
    float: left;
    height: 25px;
  
  }
  
  .drop_down_sec .dropdown-menu {
    width: 180px;
    display:none;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 45px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  }
  
  .drop_down_sec .dropdown-menu.show {
    display:block;
  }
  
  .drop_down_sec .dropdown-item svg {
    width: 20px;
    height: 25px;
    float: left;
    margin-right:10px;
    color:#222;
  }
  
  .drop_down_sec .dropdown-item {
    width: 100%;
    height: 40px;
    line-height: 25px;
    border: 0;
    padding: 0 20px;
    cursor: pointer;
    transition: 0.2s ease-in;
    background-color: #fff;
    /* font-weight: 700; */
    /* font-family: Montserrat, serif; */
    color: #000000;
    text-align: left;
    font-size: 16px;
}
  
  .drop_down_sec .dropdown-item:hover {
    background-color:#e5e5e5;
  }
  
  .drop_down_sec .dropdown-item:not(:last-child){
    border-bottom: 1px solid #e5e5e5;
  }
.drop_down_sec .apto-trigger-dropdown {
    display: flex;
}
.btn_lr_line::before {
    border-left: 1px solid;
    content: "";
    font-size: 20px;
    border-bottom: solid 1px #cbcbcb;
    position: relative;
    left: 115px;
    top: 2px;
    z-index: 1;
    transform: rotate(90deg);
    color: #cfcfcf;
}
.header_main .btn {
    /* padding: 12px 14px; */
    background-color: rgba(255, 255, 255, 0);
    border-radius: 3px;
    color: #131722;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    box-shadow: none !important;
    line-height: 0.0;
    margin-right: 6px;
    /* border: 1px solid #eee; */
    /* box-shadow: 0px 2px 4px #f5f5f5 !important; */
}
.header_main ul li{
    list-style: none;
}
.header_main ul{
    margin-bottom: 0rem;
}
.chat_window .btn {
    padding: 9px 12px;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 3px;
    color: #131722;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    box-shadow: none !important;
    line-height: 0.0;
    margin-right: 6px;
    border: 1px solid #eee;
    box-shadow: 0px 2px 4px #f5f5f5 !important;
}
.chat_window .btn:hover {
    background: #243a69;
    border: 1px solid #fff;
    color: #fff;
}
.chat_window .bottom_icon svg{
    font-size: 22px;
}
.chat_window ul li{
    list-style: none;
}
.chat_window ul{
    margin-bottom: 0rem;
}
.publish ul{
    justify-content: flex-end;
}
.technical_bg_indicat .modal-content {
    background: linear-gradient(45deg, #243a69f5, #243a69ed), url('/public/assets/image/24666.jpg');
    border-left: 5px solid #ffffff;
    border-right: 5px solid #ffffff;
    border-radius: 20px;
}
.technical_bg_indicat .modal-content h3 {
    margin-top: -12px;
    margin-bottom: 17px;
    color: #fff;
}
.technical_bg_indicat .select_stock_input_field {
    border-radius: 5px;
    box-shadow: 1px 1px 10px #00000054;
}
.technical_bg_indicat .search-input input {
    font-size: 13px;
    height: 40px;
    /* box-shadow: 1px 1px 10px #000; */
    border-radius: 5px;
}
.indicator_div::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
    background: #eee;
}
.indicator_div::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #fd7e14;
}

.modal-content {
    background: linear-gradient(45deg, #243a69f5, #243a69ed), url('/public/assets/image/24666.jpg');
    border-left: 5px solid #ffffff;
    border-right: 5px solid #ffffff;
    border-radius: 20px;
}
.stockListScrollar tr.border-bottom-0 {
    background: white;
    padding: 14px;
    line-height: 3;
}
.stockListScrollar tbody tr td {
    line-height: 2.9;
    color: #fff;
}
.stockListScrollar::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
    background: #eee;
}
.stockListScrollar::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: #fd7e14;
}
.blackout-bx .color-grey {
    background: #fff !important ;
}
.blackout-bx tbody tr th {
    line-height: 2.9;
    color: #fff;
}
.blackout-bx tbody tr td {
    line-height: 2.9;
    color: #fff;
}
.blackout-bx {
    padding: 0px;
}
@media (min-width: 768px) and (max-width: 1040px) {
    .flex-lg-fill {
        width: 100%;
    }
    .topnav_anychart {
        display: none;
    }
    .publish {
        display: none;
    }
}
.select_stock_input_field_search {
    border-radius: 5px;
    box-shadow: 1px 1px 10px #00000000;
    background-color: black;
    background-color: #ffffff00;
    border: none;
}
.search-input-charting-level {
    top: 10px !important;
}
.nav-link.active {
    background-color: #ff9152;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffff;
}
.common_active_div .nav-pills .nav-link.active, .common_active_div .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #ff7900 !important;
}
.edit_main_move svg {
    transition: 0.5s;
}
.edit_main_move:hover svg {
    transform: scale(1.25);
    transition: 0.5s;
}
.chartTypeInd{
    
        height: 163px;
        overflow-y: auto;
    
}
.theme_option_main{
    position: absolute;
    bottom: 44px;
    left: 0px;
    z-index: 999;
    width: 164px;
    background: white;
    padding: 10px;
}
.theme_option_main p{
    line-height: 1;
}

/* Dark them css start now */
.anyChart_dark .section button{
    background-color: #101013 !important;
}
.anyChart_dark .section div{
    background-color: #101013 !important;
}
.anyChart_dark .sidenav{
    background-color: #101013 !important;
}
.anyChart_dark .sidenav button{
    background-color: #101013 !important;
}
.anyChart_dark .togle_button{
    background-color: #101013 !important;
}
.anyChart_dark .footer_sec{
    background-color: #101013 !important;
}
.anyChart_dark .theme_option_main{
    background-color: #101013 !important;
}
.anyChart_dark .top-nav{
    background-color: #101013 !important;
}
.top-nav {
    padding: 4px 22px 0px 10px !important;
    background-color: #ffffff;
    border-bottom: 4px solid #e9ecef;
}
.anyChart_dark p{
    color: white !important;
}
.anyChart_dark span{
    color: white !important;
}
.anyChart_dark h1{
    color: white !important;
}
.anyChart_dark h2{
    color: white !important;
}
.anyChart_dark h3{
    color: white !important;
}
.anyChart_dark h4{
    color: white !important;
}
.anyChart_dark h5{
    color: white !important;
}
.anyChart_dark h6{
    color: white !important;
}
.anyChart_dark svg{
    color: white !important;
}
.anyChart_dark button{
    color: white !important;
}
.anyChart_dark .charting_option_main{
    background-color: #2d3748 !important;
    color: white !important;
    border: 1px solid #827f7f;
}
.anyChart_dark .charting_option_main_child{
    background-color: #2d3748 !important;
    border: 1px solid #827f7f;
}
.anyChart_dark .list_charting:hover {
    background: #4a5568;
}
.anyChart_dark .charting_option_main_child li:hover {
    background: #4a5568;
}
.anyChart_dark .tools_edit {
    background-color: #2d3748;
}
.anyChart_dark .tools_edit button {
    background-color: #2d3748;
    border: none;
}
.anyChart_dark .tools_edit button:hover {
    background-color: rgb(74, 85, 104);
}
.anyChart_dark .tools_edit input {
    background-color: #2d3748;
    color: white;
    border: none;
}
.anyChart_dark .tools_edit select {
    color: white;
}
.anyChart_dark .tools_edit option {
    background-color: #2d3748;
}
.anyChart_dark .select_option_dropDown.caption{
    background-color: #2d3748 !important;
}
.anyChart_dark .select_option_dropDown{
    background-color: #2d3748 !important;
}
.anyChart_dark .watchlist_tab_anychart {
    background-color: #2d3748 !important;
}
.anyChart_dark .watchlist_tab_anychart .menu_sbl {
    background-color: #101013 !important;
}
.anyChart_dark .watchlist_tab_anychart {
    color: white;
}
/* filter: invert(1); */


.anyChart_dark .makerSelect_option {
    background-color: #2d3748;
}
.anyChart_dark .makerSelect_option button {
    background-color: #262e3c !important;
    border: none;
}
.anyChart_dark .makerSelect_option button:hover {
    background-color: rgb(74, 85, 104);
}
.anyChart_dark .makerSelect_option img {
    filter: invert(1);
}
.border_right_2_charting{
    border-right: 2px solid #515050;
}
.anyChart_dark .border_right_2_charting{
    border-right: 2px solid #ffffff;
}
.active_chart{
    border: 2px solid rgb(0, 123, 255);
    /* box-shadow: rgb(30, 83, 229) 0.3rem 0.3rem 0.3rem 0.3rem; */
}
.anyChart_dark .list_indicator{
    background-color: #2d3748 !important;
    color: white !important;
}
.css-2q7ldg-Resizer-HorizontalResizer {
    position: relative;
    background-clip: padding-box;
    z-index: 9999;
    transition: border 500ms ease 0s, background-color 150ms ease 0s;
    height: 13px;
    margin: -6px 0px;
    cursor: row-resize;
    border-top: 6px solid rgba(255, 255, 255, 0);
    border-bottom: 6px solid rgba(255, 255, 255, 0);
    background-color: rgba(135, 149, 161, 0.1) !important;
}
.css-2q7ldg-Resizer-HorizontalResizer:hover {
    border-top: 6px solid rgba(0, 0, 0, 0.2);
    border-bottom: 6px solid rgba(0, 0, 0, 0.2);
    background-color: rgb(218, 225, 231);
}
.css-1aponxl-Resizer-VerticalResizer {
    position: relative;
    background-clip: padding-box;
    z-index: 9999;
    transition: border 500ms ease 0s, background-color 150ms ease 0s;
    width: 13px;
    margin: 0px -6px;
    cursor: col-resize;
    border-left: 6px solid rgba(255, 255, 255, 0);
    border-right: 6px solid rgba(255, 255, 255, 0);
    background-color: rgba(135, 149, 161, 0.1) !important;
}
.css-1aponxl-Resizer-VerticalResizer:hover {
    border-left: 6px solid rgba(0, 0, 0, 0.2);
    border-right: 6px solid rgba(0, 0, 0, 0.2);
    background-color: rgb(218, 225, 231);
}
/* .anyChart_dark .main_container{
    background-color: #131722;
}
.anyChart_dark .heigh_2{
    background: #131722;
}
.anyChart_dark .heigh_1{
    background: #131722;
} */